import * as React from "react"
import clsx from "clsx"
import { SearchButton } from "./SearchButton"

import * as styles from './SearchInput.module.css'

interface SearchInputProps extends React.ComponentPropsWithoutRef<"form"> {
	placeholder?: string
	defaultValue?: string
	onSearchIconClick?: () => void
}

export const SearchInput = ({
	className,
	placeholder,
	defaultValue,
	name = "query",
	onSearchIconClick,
	...props
}: SearchInputProps) => {
	const formRef = React.useRef<HTMLFormElement>(null)

	return (
		<form
			ref={formRef}
			className={clsx(styles.form, className)}
			{...props}
		>
			<input
				type="search"
				name={name}
				className={styles.input}
				placeholder={placeholder}
				defaultValue={defaultValue}
			/>

      <SearchButton
			  className={styles.button}
				type={onSearchIconClick ? "button" : "submit"}
				onClick={onSearchIconClick}
			/>
		</form>
	)
}
