import { Link } from "gatsby"

import type { SearchResult } from "../pages/search"

import * as styles from './SearchGrouping.module.css'

interface SearchGroupingProps {
	results: SearchResult[]
	displayName: string
}

export const SearchGrouping = ({
	results,
	displayName,
}: SearchGroupingProps) => {
	return (
		<li className={styles.group}>
			<h4 className={styles.type}>
				{displayName}
			</h4>

			<ul >
				{results.map((result) => (
					<li key={result.title} className={styles.result}>
						<h3 className={styles.title}>
							<Link to={result.url} className="transition hover:text-red">
								{result.title}
							</Link>
						</h3>

						{result.summary && (
							<p className={styles.summary}>
								{result.summary}
							</p>
						)}
					</li>
				))}
			</ul>
		</li>
	)
}
