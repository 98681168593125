import clsx from "clsx"
import { SearchIcon } from "./SearchIcon"
import { VisuallyHidden } from "./VisuallyHidden"

export const SearchButton = ({
	className,
	...props
}: React.ComponentPropsWithoutRef<"button">) => {
	return (
		<button className={clsx(className)} {...props}>
			<VisuallyHidden>
				<span className="sr-only">Search</span>
			</VisuallyHidden>
			<SearchIcon />
		</button>
	)
}
